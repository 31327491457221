export const isEmpty = (val) => {
  if (val == "" || val == null) {
    return true;
  }
  return false;
};

export const IsSelectInputSelected = (val) => {
  if (isEmpty(val) || val.toLowerCase().indexOf("pilih") != -1) {
    return false;
  }
  return true;
};

export const isCharExactLengthValid = (val, length) => {
  if (val.length == length) {
    return true;
  }
  return false;
};

export const isPhoneNumberLengthValid = (val, maxLength = 13) => {
  if (val.length >= 10 && val.length <= maxLength) {
    return true;
  }
  return false;
};

export const isPhoneNumberWithoutCodeLengthValid = (val) => {
  if (isPhoneNumberLengthValid(val, 11)) {
    return true;
  }
  return false;
};

export const isPhoneNumberFormat = (val) => {
  if (val.startsWith("08") || val.startsWith("628")) {
    return true;
  }
  return false;
};

export const isEmailFormat = (val) => {
  // Example of valid email id
  // mysite@ourearth.com
  // my.ownsite@ourearth.org
  // mysite@you.me.net

  // Example of invalid email id
  // mysite.ourearth.com [@ is not present]
  // mysite@.com.my [ tld (Top Level domain) can not start with dot "." ]
  // @you.me.net [ No character before @ ]
  // mysite123@gmail.b [ ".b" is not a valid tld ]
  // mysite@.org.org [ tld can not start with dot "." ]
  // .mysite@mysite.org [ an email should not be start with "." ]
  // mysite()*@gmail.com [ here the regular expression only allows character, digit, underscore, and dash ]
  // mysite..1234@yahoo.com [double dots are not allowed]

  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (regex.test(val)) {
    return true;
  }
  return false;
};

export const isCharLengthValid = (val, minLength = 3, maxLength = 25) => {
  if (val.length >= minLength && val.length <= maxLength) {
    return true;
  }
  return false;
};

export const isIdCardLengthValid = (val, maxLength = 16) => {
  if (val.length == maxLength) {
    return true;
  }
  return false;
};